import React, { useState } from "react"
import { XCircleIcon, XIcon } from "@heroicons/react/solid"

var error = 2

const Failed = () => {
  const [hide, setHidden] = useState(false)

  return (
    <div
      hidden={hide}
      className="space-y-8 justify-center max-w-5xl mx-auto rounded-md bg-red-50 p-4"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Kunne ikke fullføre registreringen
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>
                Gjenstår problemet, ta kontakt med oss på{" "}
                <a
                  href="mailto: firmapost@dlf.no"
                  className="font-medium text-link-500 hover:text-link-400"
                >
                  epost
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => setHidden(true)}
              className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Failed
