import React, { useState } from "react"
import { graphql } from "gatsby"
import Endre from "../components/endre"
import { CenteringStyle } from "../components/Styling/ComponentStyles"
import MyPageLayout from "../components/Layout/MyPageLayout"

const EndreBruker = ({ props }) => {
  // const contact = location.state?.contact
  const contact = props?.contact
  // console.log("Kontaktid: " + contact.id)
  return (
    <MyPageLayout props="DLF - Endre Bruker">
      <div className="mb-20">
        <Endre contact={contact} />
      </div>
    </MyPageLayout>
  )
}

export default EndreBruker
