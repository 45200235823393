import React, { useState } from "react"
import { CheckCircleIcon, XIcon } from "@heroicons/react/solid"
import { Link, navigate } from "gatsby"
import axios from "axios"

const emptyGuid: string = "00000000-0000-0000-0000-000000000000"

const Success = props => {
  const uname = props.props
  // var callback: (contact) => void;
  // const [contact, setContact] = useState(null)
  
  const [hide, setHidden] = useState(false)
  return (
    <div
      hidden={hide}
      className="space-y-8 justify-center max-w-5xl mx-auto rounded-md bg-green-50 p-4"
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Lagring vellykket
          </p>
          <button
            onClick={() => navigate("/")}
            className="font-medium text-logo-500 hover:text-logo-400"
          >
            Gå til min side
          </button>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => setHidden(true)}
              className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success
