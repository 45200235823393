
export const msalConfig = {
    auth: {
        clientId: "",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "/",
        postLogOutRedirect: "/"
    }
}

export const loginRequest = {
    scopes: ["User.Read"]
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoftonline.com/v1.0/me"
}