import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import Success from "../components/AlertBoxes/success"
import Failed from "../components/AlertBoxes/failed"
import { Link } from "gatsby"
import { ErrorMessage } from "@hookform/error-message"
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
import { loginRequest } from "../Authentication/AuthConfig"
import { authToken } from "./Security/authToken"
import { Contact } from "../Utils/Entities"
import { resetWarningCache } from "prop-types"

type FormValues = {
  id: string
  firstName: string
  lastName: string
  jobTitle: string
  // emailAddress: string
  businessPhone: string
  mobilePhone: string
  newsLetter: boolean
}

const EndreContent = props => {
  const { instance, accounts, inProgress } = useMsal()
  const accessToken = authToken(accounts, inProgress, instance)
  // var contact = props.contact
  const [contact, setContact] = useState<Contact>()

  // let substringEmail = ""

  // if (contact?.emailAddress) {
  //   substringEmail = contact?.emailAddress.split("@")
  // }

  //var enable=contact.newsLetter

  const [showSuccess, setSuccessState] = useState(false)
  const [showFailed, setFailedState] = useState(false)
  const [showDuplicateError, setDuplicateError] = useState(false)

  const [enabled, setEnabled] = useState(contact?.newsLetter)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>()

  useEffect(() => {
    const getContact = async () => {
      if (await accessToken) {
        // let c : Contact;
        await axios
          .get(`${process.env.GATSBY_API_URL}/Contacts/me`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + (await accessToken),
            },
          })
          .then(response => {
            setContact(response.data)
            reset(response.data)
            // c = response.data;
            // return response.data
          })
          .catch(error => {
            // return c
          })
      }
    }

    getContact()
  }, [])

  const editContact = async (data, event) => {
    data.id = contact?.id
    await axios
      .patch(`${process.env.GATSBY_API_URL}/Contacts/edit`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + (await accessToken),
        },
      })
      .then(response => {
        setSuccessState(true)
        setFailedState(false)
      })
      .catch(error => {
        setFailedState(true)
        setSuccessState(false)
      })
    event.preventDefault()
  }

  const testing = value => {
    setEnabled(value)
  }

  return (
    <div className="mb-5">
      <div className="mt-20 space-y-8 justify-center">
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 max-w-5xl mx-auto my-4">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Endre informasjon
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form method="PATCH" onSubmit={handleSubmit(editContact)}>
                <div className="grid grid-cols-6 gap-6">
                  <div className="hidden">
                    <label htmlFor="id">id</label>
                    <input
                      type="text"
                      {...register("id")}
                      name="id"
                      id="id"
                      defaultValue={contact?.id}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <div className="flex items-baseline mb-0 pb-0">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fornavn
                      </label>
                      <span className="text-red-600 font-bold"> *</span>
                    </div>
                    <input
                      {...register("firstName", {
                        pattern: {
                          value: /^[A-Za-zÆØÅæøå ]+$/i,
                          message: "Kun bokstaver (a-å, A-Å) er tillatt",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maks tegn er 50",
                        },
                      })}
                      required
                      type="text"
                      name="firstName"
                      id="firstName"
                      defaultValue={contact?.firstName}
                      autoComplete="given-name"
                      className="mt-1 focus:ring-logo-500 focus:border-logo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="firstName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <div className="flex items-baseline mb-0 pb-0">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Etternavn
                      </label>
                      <span className="text-red-600 font-bold"> *</span>
                    </div>
                    <input
                      {...register("lastName", {
                        pattern: {
                          value: /^[A-Za-zÆØÅæøå ]+$/i,
                          message: "Kun bokstaver (a-å, A-Å) er tillatt",
                        },
                        minLength: {
                          value: 2,
                          message: "Minimum tegn er 2",
                        },
                        maxLength: {
                          value: 50,
                          message: "Maks tegn er 50",
                        },
                      })}
                      type="text"
                      name="lastName"
                      id="lastName"
                      required
                      defaultValue={contact?.lastName}
                      autoComplete="family-name"
                      className="mt-1 focus:ring-logo-500 focus:border-logo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="lastName"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <div className="flex items-baseline mb-0 pb-0">
                      <label
                        htmlFor="jobTitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tittel
                      </label>
                      <span className="text-red-600 font-bold"> *</span>
                    </div>
                    <input
                      {...register("jobTitle", {
                        pattern: {
                          value: /^[A-Za-zÆØÅæøå ]+$/i,
                          message: "Kun bokstaver (a-å, A-Å) er tillatt",
                        },
                        minLength: {
                          value: 3,
                          message: "Minimum tegn er 3",
                        },
                        maxLength: {
                          value: 220,
                          message: "Maks tegn er 220",
                        },
                      })}
                      required
                      type="text"
                      name="jobTitle"
                      id="jobTitle"
                      defaultValue={contact?.jobTitle}
                      className="mt-1 focus:ring-logo-500 focus:border-logo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="jobTitle"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="businessPhone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telefon, arbeid
                    </label>
                    <input
                      {...register("businessPhone", {
                        pattern: {
                          value: /^[0-9]{8}$/i,
                          message: "Kun 8 siffer er tillatt",
                        },
                      })}
                      type="text"
                      name="businessPhone"
                      id="businessPhone"
                      autoComplete="phone"
                      defaultValue={contact?.businessPhone}
                      className="mt-1 focus:ring-logo-500 focus:border-logo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="businessPhone"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="mobilePhone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobil
                    </label>
                    <input
                      {...register("mobilePhone", {
                        pattern: {
                          value: /^[0-9]{8}$/i,
                          message: "Kun 8 siffer er tillatt",
                        },
                      })}
                      type="text"
                      name="mobilePhone"
                      id="mobilePhone"
                      autoComplete="phone"
                      defaultValue={contact?.mobilePhone}
                      className="mt-1 focus:ring-logo-500 focus:border-logo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="mobilePhone"
                      render={({ message }) => (
                        <p className="text-sm text-red-600">{message}</p>
                      )}
                    />
                  </div>

                  <div className="pl-12 col-span-6 sm:col-span-5">
                    <div className="flex items-center justify-between">
                      <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">
                          Nyhetsbrev
                        </span>
                        <span className="text-sm text-gray-500">
                          Jeg ønsker å få nyhetsbrev
                        </span>
                      </span>
                      <input
                        {...register("newsLetter")}
                        type="checkbox"
                        id="newsLetter"
                        key="newsLetter"
                        defaultChecked={enabled}
                        onChange={testing}
                        className="text-logo-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
                      />
                    </div>
                  </div>

                  <div></div>
                </div>
                <div className="flex justify-center mt-4 pt-4 px-2 pb-4">
                  <Link to={`/`}>
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
                    >
                      Avbryt
                    </button>
                  </Link>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-logo-600 hover:bg-logo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-logo-500"
                  >
                    Lagre
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <h3 className="text-center text-link-500 font-sm italic text-medium mt-10">
          Hvis du har sluttet og begynt i ny DLF medlemsbedrift send oss en
          <a
            href="mailto: firmapost@dlf.no"
            className="font-medium text-link-500 hover:text-link-400"
          >
            {" "}
            mail.
          </a>
        </h3>
      </div>
      <div className="mb-2">
        {showSuccess ? <Success /> : <div></div>}
        {showFailed ? <Failed /> : <div></div>}
      </div>
    </div>
  )
}

const Endre = props => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <EndreContent contact={props.contact} />
    </MsalAuthenticationTemplate>
  )
}

export default Endre
